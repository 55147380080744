.paragraph {
  @apply pb-6 overflow-x-clip;

  & :global(.object-tag) {
    @apply pb-0;
  }

  &:global(.layout-2-col) {
    @apply columns-1 md:columns-2 gap-10;
  }

  & a {
    @apply underline;
  }

  /* Slightly more gap when paragraph is followed by theme-block */
  & + div[data-block^="theme/"] {
    @apply pt-4;
  }

  &:global(.layout-align-left) {
    @apply max-w-8xl mx-auto;
    & > * {
      @apply max-w-1xl;
    }
  }
}
